import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './main.css'

import defaultPausebilde from 'url:../public/media/default-pausebilde.jpg';
import defaultTrekningAudio from 'url:../public/media/new-notification-on-your-device.mp3';

import { Elm } from './Main.elm'

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth' 
import { getFirestore, collection, getDocs, getDoc, doc, addDoc, updateDoc, onSnapshot, query, setDoc, deleteDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

// import firebaseConfig from './__/firebase/init.json'

// import registerServiceWorker from './registerServiceWorker'
// registerServiceWorker()

// Initialize Firestore
const firebaseApp = initializeApp({
  "apiKey": "AIzaSyA86ppnToTY4Q0Kee8sWx9g3KDlJeBIm4Y",
  "authDomain": "visno-elm.firebaseapp.com",
  "projectId": "visno-elm",
  "storageBucket": "visno-elm.appspot.com"
})

const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const auth = getAuth(firebaseApp)

// Start BroadcastChannel
const channel = new BroadcastChannel("skjerm-1");
channel.addEventListener("message", function(event) {
  console.log('📺 Mottatt data over BroadcastChannel:', event.data)
  app.ports.skjermResponse.send(event.data)
});


// Start ELM
const app = Elm.Main.init({
  flags: {
    version: "v2024.12.0-beta.1",
    defaultBackground: defaultPausebilde,
    defaultTrekningAudio: defaultTrekningAudio,
    inIframe: window.self !== window.top
  }
})

// PORTS ###################################################################

if (false) {
  app.ports.innport.send('ikke i bruk')
}

app.ports.utport.subscribe(({ tag, collectionPath, documentId, innhold }) => {
  console.log('infoFraElm: (tag, collectionPath, innhold) ' + tag, collectionPath, innhold)
  switch (tag) {
    case 'Registrering':
      createUserWithEmailAndPassword(auth, innhold.epost, innhold.passord)
        .then((userCredential) => {
          const user = userCredential.user;
        })
        .catch((error) => {
          logError('Feil med registrering: ' + error)
        })
      return

    case 'LoggInn':
      console.log('Logger inn.', tag, collectionPath, innhold)
      signInWithEmailAndPassword(auth, innhold.epost, innhold.passord)
        .catch((error) => {
          logError('Feil med innlogging: ' + error)
          var test = { uid: '', epost: '', error: error.message }
          app.ports.updateAuthState.send(test)
        })
      return

    case 'LoggUt':
      signOut(auth)
        .then(console.log('Logger ut...') )
        .catch(logError('Feil med å logge ut.'))
      return

    case 'FirestoreCollectionAdd':
      CollectionAdd(collectionPath, innhold)
      return

    case 'LagreSangtekst':
      console.log('TODO: LAGRE sANGTEKST!!!!', tag, collectionPath, innhold)
      return

    case 'FirestoreCollectionGet':
      CollectionGet(collectionPath)
      return

    case 'FirestoreDocumentUpdate':
      DocumentUpdate(collectionPath, documentId, innhold)
      return

    case 'SendTilSkjerm':
      channel.postMessage(innhold); // Send data over BroadcastChannel (lokalt, mellom nettleservindu)
      DocumentUpdate('skjerm', collectionPath, innhold) // Lagre til Firebase -> subscribe -> Skjerm (over internett)
      return

    case 'FirestoreDocumentDelete':
      DocumentDelete(collectionPath, documentId)
      return

    case 'StorageUpload':
      console.log('Laster opp filer...')
      upload(innhold)
      return
    
    case 'StartLydeffekt':
      var audio = new Audio(defaultTrekningAudio);
      audio.play();

    default:
      logError('ERROR: infoFraElm ikke håndtert, tag: ' + tag)
  }
})

function upload(x){
  var file = document.getElementById(x.inputId).files[0];
  var storageRef = ref(storage, "users/" + x.brukerId + "/" + file.name)

  var metadata = {
    contentType: 'image/jpeg'
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  var uploadTask = uploadBytesResumable(storageRef, file, metadata);

// Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed', // or 'state_changed'
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      
      switch (snapshot.state) {
        case 'paused': // or 'paused'
          console.log('Upload is paused')
          break
        case 'running': // or 'running'
          console.log('Upload is ' + progress + '% done')
          app.ports.uploadProgress.send(progress)
          break
      }
    }, (error) => {

      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          console.log("User doesn't have permission to access the object")
          break

        case 'storage/canceled':
          console.log("User canceled the upload")
          break

        case 'storage/unknown':
          console.log("Unknown error occurred, inspect error.serverResponse")
          break
      }
    }, function() {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL)
          var collectionPath = "brukere/" + x.brukerId + "/pausebilder"
          var innhold = { "pausebildeUrl": downloadURL }
          CollectionAdd(collectionPath, innhold)
        });
    });
}

function logError (message) {
  return error => { console.error(message, error) }
}

function CollectionGet (dok) {
  console.log("CollectionGet: (dok)", dok)
  onSnapshot(collection(firestore, dok), (querySnapshot) => {
      let st = []
      querySnapshot.forEach((listeElement) => {
        let data = listeElement.data()
        data.id = listeElement.id
        st.push(data)
      })
      var receivePort = collectionUrlToResponsePort(dok)
      console.log('Sending response', 'CollectionGet', receivePort, st)
      app.ports[receivePort].send(st)
    })
}

/***
 * COLLECTION
 */

function CollectionAdd (collectionName, melding) {
  console.log('CollectionAdd: ', melding)
  const collectionRef = collection(firestore, collectionName);
  addDoc(collectionRef, melding)
    .then(function (docRef) { console.log('Document written with ID: ', docRef.id, collectionName) })
    .catch(function (error) { console.error('Error adding document: ', error) })
}

function DocumentUpdate (collectionPath, documentId, melding) {
  console.log('DocumentUpdate. {collectionPath, dokid, melding}: ', collectionPath, documentId, melding)
  const docRef = doc(firestore, collectionPath, documentId);
  setDoc(docRef, melding)
    .then(function (docRef) { console.log('Document set.', melding) })
    .catch(function (error) { console.error('Error update document: ', error) })
}

function DocumentDelete (collectionPath, documentId) {
  console.log('DocumentDelete. {collectionPath, documentId}: ', collectionPath, documentId)
  const docRef = doc(firestore, collectionPath + "/" + documentId)
  deleteDoc(docRef)
    .then(() => console.log('Document deleted.'))
    .catch(error => console.error('Error delete document: ', error))
}

//app.ports.lagreSangtekst.subscribe(function (melding) {
//  CollectionAdd('sangtekster', melding)
//})

// sendPausebildeTilSkjerm og sendVersTilSkjerm må settes sammen

app.ports.kopierSangtekstTilKjoreplan.subscribe(function (data) {
  var kjoreplanId = data[0]
  var melding = data[1]
  CollectionAdd('kjoreplaner/' + kjoreplanId + '/sangtekster', melding)
})

/**
 * Henter ut en liste med objekter fra Cloud Firestore. Objektene må eksistere som Elm-modeller.
 * Det må eksistere en subscription port <collectionName>CollectionResponse.
 */
/* app.ports.hentCollection.subscribe(collectionName => {
  console.log(collectionName)
  firestore.collection(collectionName).onSnapshot((query) => {
    console.log(query)
    var st = []
    query.forEach(function (listeElement) {
      var data = listeElement.data()
      data.id = listeElement.id
      st.push(data)
    })
    console.log('ST', st)
    console.log(app.ports)
    var receivePort = collectionUrlToResponsePort(collectionName)
    console.log(receivePort)
    app.ports[receivePort].send(st)
  })
}) */
var unsubscribeSkjerm = () => { };
var unsubscribeBrukere = () => { };
app.ports.hentDocument.subscribe(docRef => {
  console.log('😇 Setter opp subscription for hentDocument: ', docRef)
  switch (docRef.split('/')[0]) {
    
    case 'skjerm':
      unsubscribeSkjerm();
      unsubscribeSkjerm = onSnapshot(doc(firestore, docRef), (doc) => {
        //console.log('hentDokument: ', doc.ref.parent.path)
        if (doc.exists) {
          console.log('🛜 Mottatt data over via Firebase subscribe (internett)', doc.data())
          app.ports.skjermResponse.send(doc.data()) // Denne må bygges for å støtte flere muligheter. Best å sende som input til hentDocument hvilken skal respondere på?
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!', docRef)
        }  
      });
      break

    case 'brukere': // settings lagret på brukere/<brukerId>
      unsubscribeBrukere();
      unsubscribeBrukere = onSnapshot(doc(firestore, docRef), (doc) => {
        console.log('Mottatt settings fra Firebase', doc.data())
        app.ports.brukereResponse.send(doc.data())
      });
      break

    default:
      console.log('Kan ikke sette opp abonnement på dokument i Firebase som ikke er håndtert!', doc.data())
      break
  }
  
})

/**
 * For eksempel `brukere/12345/sangtekster` returnerer `brukere_sangteksterCollectionResponse`
 * @param url collection url
 * @returns navn på port 
 */
function collectionUrlToResponsePort (url) {
  var splittedUrl = url.split('/')
  var x = splittedUrl
            .filter((value, index) => !(index % 2))
            .join('_')
  var responsePortNname = x + 'CollectionResponse'
  return responsePortNname
}

/**
 * Holder auth state i sync med elm model state
 */
onAuthStateChanged(auth, 
  (user) => {
    if (user) {
      console.log('Innlogget bruker: ', user)
      var uid = user.uid
      var test = { uid: uid, epost: user.email, error: "" }
    } else {
      console.log('Bruker er nå logget ut.')
      var test = { uid: '', epost: '', error: "" }
    }
    app.ports.updateAuthState.send(test)
  }, (error) => {
    console.log('Feil med innlogging: ' + error)
    var test = { uid: '', epost: '' }
    app.ports.updateAuthState.send(test)
  }
)

// DIVERSE SKJERM-EKSEMPLER

function openDebugWindow (moduleName, popoutRef, virtualNode, eventNode) {
  var w = 900
  var h = 360
  var x = (screen.width - w) / 2
  var y = (screen.height - h) / 2
  var debugWindow = window.open('', '', 'width=' + w + ',height=' + h + ',left=' + x + ',top=' + y)
}

function openFullscreen(){
  document.body.requestFullscreen();
}